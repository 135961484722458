/* SHARED STYLES */
.blog-page .blog-card,
.blog-info-page .blog-card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  gap: 10px;
}
.blog-page .web,
.blog-info-page .web {
  display: block !important;
}
.blog-page .mob,
.blog-info-page .mob {
  display: none !important;
}
.blog-page .regular-card-container,
.blog-info-page .regular-card-container {
  width: 300px;
  height: 405px;
  gap: 10px;
  margin-bottom: 30px;
  /* padding: 20px 0; */

  /*   border-radius: 5px;
  box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.5);
  -webkit-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.5);
  -moz-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.5); */
}
.blog-page .small-card-container,
.blog-info-page .small-card-container {
  width: 240px;
  gap: 5px;
}
.blog-page .force-container,
.blog-info-page .force-container {
  flex: 1;
  gap: 5px;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  width: 100%;
}
.blog-page .blog-card:not(.big-card-container) .force-container,
.blog-info-page .blog-card:not(.big-card-container) .force-container {
  height: 215px;
  overflow: hidden;
  flex: unset;
}
.blog-page .blog-card .absolute-link,
.blog-info-page .blog-card .absolute-link {
  margin-top: 10px;
  margin-bottom: -40px;
}
.blog-page .blog-card .tag-card,
.blog-info-page .blog-card .tag-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  padding: 0px 10px;

  width: 165px;
  height: 30px;

  background-color: var(--secondary-dark-color);
  clip-path: polygon(0 0, 100% 0%, 75% 100%, 0% 100%);
  /* margin-bottom: 5px; */
}
.blog-page .blog-card .tag-card .ant-btn,
.blog-info-page .blog-card .tag-card .ant-btn {
  font-family: "EMprint-Semibold";
  color: white;
  padding: 0px !important;
}
.blog-page .blog-card .tag-card-skeleton,
.blog-info-page .blog-card .tag-card-skeleton {
  width: 165px;
  height: 30px;
  clip-path: polygon(0 0, 100% 0%, 75% 100%, 0% 100%);
}
.blog-page .blog-card:not(.big-card-container) .tag-card,
.blog-info-page .blog-card:not(.big-card-container) .tag-card {
  width: 120px;
  height: 20px;
}
.blog-page .blog-card:not(.big-card-container) .tag-card-skeleton,
.blog-info-page .blog-card:not(.big-card-container) .tag-card-skeleton {
  width: 120px;
  height: 20px;
}
.blog-page .blog-card .cover-info,
.blog-info-page .blog-card .cover-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: end;
  width: 100%;
  gap: 10px;
  flex-wrap: nowrap !important;
}
.blog-page .blog-card .cover-info p,
.blog-info-page .blog-card .cover-info p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.blog-page .big-card-container .cover-info,
.blog-info-page .big-card-container .cover-info {
  margin-bottom: 10px;
}
.blog-page .regular-card-container .cover-info,
.blog-info-page .regular-card-container .cover-info {
  flex-wrap: wrap !important;
  margin: 0px;
  min-height: 40px;
  gap: 0px 10px;
}
.blog-page .small-card-container .cover-info,
.blog-info-page .small-card-container .cover-info {
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 5px;
}
.blog-page .blog-card .tag-card p,
.blog-info-page .blog-card .tag-card p,
.blog-page .blog-card .tag-card a,
.blog-info-page .blog-card .tag-card a {
  font-size: 20px;
  line-height: 25px;
  font-weight: 600;
  color: #fff;
  text-align: left;
  margin: 0px;
  transition: all ease 0.5s;
}
.blog-page .blog-card .tag-card a:hover,
.blog-page .blog-card .tag-card a:focus,
.blog-page .blog-card .tag-card a:active,
.blog-info-page .blog-card .tag-card a:hover,
.blog-info-page .blog-card .tag-card a:focus,
.blog-info-page .blog-card .tag-card a:active {
  color: #fff !important;
  opacity: 0.9;
}
.blog-page .blog-card:not(.big-card-container) .tag-card p,
.blog-info-page .blog-card:not(.big-card-container) .tag-card p,
.blog-page .blog-card:not(.big-card-container) .tag-card a,
.blog-info-page .blog-card:not(.big-card-container) .tag-card a {
  font-size: 14px !important;
}
.blog-page .blog-card .image-card,
.blog-info-page .blog-card .image-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 300px;
  margin: 0 auto;
}
.blog-page .blog-card .image-card a,
.blog-info-page .blog-card .image-card a {
  height: 100%;
}
.blog-page .blog-card .image-card .ant-btn,
.blog-info-page .blog-card .image-card .ant-btn {
  height: 100%;
  padding: 0px !important;
}
.blog-page .big-card-container .image-card .ant-image,
.blog-info-page .big-card-container .image-card .ant-image {
  width: 660px;
  height: 300px;
}
.blog-page .regular-card-container .image-card,
.blog-info-page .regular-card-container .image-card {
  height: 150px;
}
.blog-page .regular-card-container .image-card .ant-image,
.blog-info-page .regular-card-container .image-card .ant-image {
  width: 300px;
  height: 150px;
}
.blog-page .small-card-container .image-card,
.blog-info-page .small-card-container .image-card {
  height: 150px;
}
.blog-page .small-card-container .image-card .ant-image,
.blog-info-page .small-card-container .image-card .ant-image {
  width: 240px;
  height: 150px;
}
.blog-page .blog-card .image-card .cover-image,
.blog-info-page .blog-card .image-card .cover-image {
  border-radius: 6px !important;
  min-width: 100% !important;
  min-height: 100% !important;
  object-fit: cover;
}
.blog-page .blog-card .image-card .cover-image-skeleton,
.blog-info-page .blog-card .image-card .cover-image-skeleton {
  border-radius: 6px !important;
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
}
.blog-page .blog-card .cover-title,
.blog-info-page .blog-card .cover-title {
  padding: 0px;
  text-align: start;

  font-size: 32px;
  line-height: normal;
  font-weight: 600;
  color: var(--mariner-color);
  /* 
  text-align: left;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;  
  */
  width: 100%;
  margin-bottom: 5px;
  letter-spacing: 0em;
  transition: all ease 0.5s;
}
.blog-page .blog-card .cover-title:hover,
.blog-info-page .blog-card .cover-title:hover {
  cursor: pointer;
}
.blog-page .blog-card a.cover-title:hover,
.blog-page .blog-card a.cover-title:focus,
.blog-page .blog-card a.cover-title:active,
.blog-info-page .blog-card a.cover-title:hover,
.blog-info-page .blog-card a.cover-title:focus,
.blog-info-page .blog-card a.cover-title:active {
  color: var(--mariner-color) !important;
  opacity: 0.9;
}
.blog-page .regular-card-container .cover-title,
.blog-info-page .regular-card-container .cover-title {
  font-size: 24px;
  margin: 0px;
}
/* .blog-page .regular-card-container .cover-title span,
.blog-info-page .regular-card-container .cover-title span {
  font-size: 24px;
  margin: 0px;
} */
.blog-page .small-card-container .cover-title,
.blog-info-page .small-card-container .cover-title {
  font-size: 24px;
  white-space: normal !important;
  margin: 0px;
}
/* .blog-page .small-card-container .cover-title span,
.blog-info-page .small-card-container .cover-title span {
  font-size: 24px;
  margin: 0px;
} */
.blog-page .blog-card .cover-info .time,
.blog-info-page .blog-card .cover-info .time {
  display: flex;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  font-size: 14px;
  line-height: 17px;
  font-weight: 400;
  color: #444444;
  text-align: start;

  letter-spacing: 0em;

  margin: 0px;
  gap: 2.5px;
}
.blog-page .blog-card .cover-info .time span,
.blog-info-page .blog-card .cover-info .time span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.blog-page .blog-card .cover-info .autor,
.blog-info-page .blog-card .cover-info .autor {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  color: #444444;
  text-align: center;

  letter-spacing: 0em;

  margin: 0px;
}
/* .blog-page .blog-card .cover-info .autor span,
.blog-info-page .blog-card .cover-info .autor span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
} */
.blog-page .blog-card .cover-info .date,
.blog-info-page .blog-card .cover-info .date {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  font-size: 14px;
  line-height: 17px;
  font-weight: 400;
  font-weight: 400;
  color: #444444;
  text-align: end;

  letter-spacing: 0em;

  margin: 0px;
}
.blog-page .blog-card .cover-info .date p,
.blog-info-page .blog-card .cover-info .date p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.blog-page .big-card-container .cover-info .time,
.blog-info-page .big-card-container .cover-info .time {
  flex: 1;
}
.blog-page .big-card-container .cover-info .autor,
.blog-info-page .big-card-container .cover-info .autor {
  flex: 1;
}
.blog-page .big-card-container .cover-info .date,
.blog-info-page .big-card-container .cover-info .date {
  flex: 1;
}
.blog-page .regular-card-container .cover-info .time,
.blog-info-page .regular-card-container .cover-info .time {
  font-size: 12px;
  line-height: 14.72px;
  width: 100% !important;
}
.blog-page .regular-card-container .cover-info .autor,
.blog-info-page .regular-card-container .cover-info .autor {
  flex: 1;
  font-size: 12px;
  line-height: 14.72px;
  text-align: start;
}
.blog-page .regular-card-container .cover-info .date,
.blog-info-page .regular-card-container .cover-info .date {
  flex: 1;
  font-size: 12px;
  line-height: 14.72px;
}
.blog-page .small-card-container .cover-info .time,
.blog-info-page .small-card-container .cover-info .time {
  font-size: 12px;
  line-height: 14.72px;
  font-weight: 400;
  width: 100%;
}
.blog-page .small-card-container .cover-info .autor,
.blog-info-page .small-card-container .cover-info .autor {
  flex: 1;
  font-size: 12px;
  line-height: 14.72px;
  text-align: start;
}
.blog-page .small-card-container .cover-info .date,
.blog-info-page .small-card-container .cover-info .date {
  font-size: 12px;
  line-height: 14.72px;
  text-align: start;
}
.blog-page .blog-card .cover-desc,
.blog-info-page .blog-card .cover-desc {
  /* 
  display: flex;
  flex-direction: column;

  width: 100%;
  max-height: 80px;
  */

  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.blog-page .blog-card .desc,
.blog-info-page .blog-card .desc {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #000;
  margin: 0px;

  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  text-align: left;

  letter-spacing: 0em;

  /* 
  max-height: 80px;
  -webkit-line-clamp: 3;
  */

  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.blog-page .blog-card.is-content .desc,
.blog-info-page .blog-card.is-content .desc {
  color: #000;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}

/* 
.blog-page .regular-card-container .desc,
.blog-info-page .regular-card-container .desc {
  line-height: 19.63px;
}
.blog-page .small-card-container .desc,
.blog-info-page .small-card-container  .desc {
  line-height: 19.63px;
}
 */
.blog-page .blog-card .link-container,
.blog-info-page .blog-card .link-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  width: 100%;

  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  letter-spacing: 0em;
  text-align: right;

  text-decoration: var(--primary-color) underline !important;
}
.blog-page .blog-card .link-container a,
.blog-info-page .blog-card .link-container a {
  color: var(--primary-color);
}
.blog-page .blog-card .link-container .ant-btn,
.blog-info-page .blog-card .link-container .ant-btn {
  margin: 0px !important;
  padding: 0px !important;
}
.blog-page .blog-card .link-container .ant-btn span,
.blog-info-page .blog-card .link-container .ant-btn span {
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  letter-spacing: 0em;
  text-align: right;

  text-decoration: underline !important;
  color: var(--primary-color);
}

/* ==========================================================================
  Big card specifics
  ========================================================================== */
.blog-page .blog-card.big-card-container .cover-info p,
.blog-info-page .blog-card.big-card-container .cover-info p {
  flex: unset;
  width: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 2px;
}
@media (max-width: 768px) {
  .blog-page .blog-card.big-card-container .image-card,
  .blog-page .blog-card.big-card-container .image-card .ant-image,
  .blog-info-page .blog-card.big-card-container .image-card,
  .blog-info-page .blog-card.big-card-container .image-card .ant-image {
    width: 100%;
    max-width: 660px;
    height: auto;
    aspect-ratio: 660/300;
  }

  .blog-page .blog-card.big-card-container .image-card,
  .blog-page .blog-card.big-card-container .image-card .ant-image,
  .blog-info-page .blog-card.big-card-container .image-card {
    width: 100%;
    max-width: 660px;
    height: auto;
    aspect-ratio: 660/300;
  }

  .blog-page .blog-card.big-card-container .cover-info,
  .blog-info-page .blog-card.big-card-container .cover-info {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }

  .blog-page .blog-card.big-card-container .cover-info p,
  .blog-info-page .blog-card.big-card-container .cover-info p {
    flex: unset;
    width: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 2px;
  }
}
/* ==========================================================================
  /Big card specifics
  ========================================================================== */

/* ==========================================================================
  Small card specifics
  ========================================================================== */
@media (max-width: 1330px) {
  .blog-page .web,
  .blog-info-page .web {
    display: none !important;
  }
  .blog-page .mob,
  .blog-info-page .mob {
    display: block !important;
  }
  .blog-page .small-card-container,
  .blog-info-page .small-card-container {
    flex-direction: column;
    width: 150px;
  }
  .blog-page .small-card-container .image-card,
  .blog-page .small-card-container .image-card .ant-image,
  .blog-info-page .small-card-container .image-card,
  .blog-info-page .small-card-container .image-card .ant-image {
    width: 150px;
    height: 100px;
  }
  .blog-page .small-card-container .image-card,
  .blog-info-page .small-card-container .image-card {
    min-width: 150px !important;
    max-width: 150px !important;
    height: 101px !important;
  }
  .blog-page .small-card-container .image-card a,
  .blog-info-page .small-card-container .image-card a {
    min-width: 150px;
    max-width: 150px;
    height: 100px;
  }
  .blog-page .small-card-container .cover-title,
  .blog-info-page .small-card-container .cover-title {
    font-size: 20px !important;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
  }
  .blog-page .small-card-container .cover-info,
  .blog-info-page .small-card-container .cover-info {
    display: none !important;
  }
  .blog-page .small-card-container .cover-info .autor,
  .blog-info-page .small-card-container .cover-info .autor {
    display: none;
  }
  .blog-page .small-card-container .cover-info .time,
  .blog-info-page .small-card-container .cover-info .time {
    display: none;
  }
  .blog-page .small-card-container .cover-info .date,
  .blog-info-page .small-card-container .cover-info .date {
    display: none;
  }
  .blog-page .small-card-container .cover-desc,
  .blog-info-page .small-card-container .cover-desc {
    display: none !important;
  }
  .blog-page .small-card-container .link-container,
  .blog-info-page .small-card-container .link-container {
    display: none !important;
  }
  .blog-page .small-card-container .force-container,
  .blog-info-page .small-card-container .force-container {
    height: 80px !important;
  }
}
/* ==========================================================================
  Small card specifics
  ========================================================================== */

@media only screen and (max-width: 768px) {
  .blog-page .regular-card-container,
  .blog-info-page .regular-card-container {
    width: 100%;
    height: 120px;
    flex-direction: row;
    border-bottom: 1px dashed #ccc;
    padding-bottom: 20px;
    margin-bottom: 20px;
    position: relative;
  }

  .blog-page .blog-card:not(.big-card-container) .force-container,
  .blog-info-page .blog-card:not(.big-card-container) .force-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100px !important;
  }
  .blog-page .blog-card .tag-card,
  .blog-info-page .blog-card .tag-card {
    z-index: 2;
    width: 120px;
    height: 20px;

    padding: 0px 5px;

    margin-bottom: -30px;
  }
  .blog-page .blog-card .tag-card-skeleton,
  .blog-info-page .blog-card .tag-card-skeleton {
    z-index: 2;
    width: 120px;
    height: 20px;

    margin-bottom: -30px;
  }
  .blog-page .blog-card:not(.big-card-container) .tag-card,
  .blog-info-page .blog-card:not(.big-card-container) .tag-card {
    position: absolute;
    top: 0;
    left: 0;
  }
  .blog-page .blog-card:not(.big-card-container) .tag-card-skeleton,
  .blog-info-page .blog-card:not(.big-card-container) .tag-card-skeleton {
    position: absolute;
    top: 0;
    left: 0;
  }
  /* .blog-page .small-card-container .tag-card,
  .blog-info-page .small-card-container .tag-card {
    width: 80px;

    margin-bottom: -25px !important;
    margin-right: -85px !important;
  } */
  .blog-page .small-card-container .tag-card,
  .blog-info-page .small-card-container .tag-card {
    position: relative !important;
  }
  .blog-page .blog-card .cover-info,
  .blog-info-page .blog-card .cover-info {
    justify-self: flex-end !important;
  }
  .blog-page .regular-card-container .cover-info,
  .blog-info-page .regular-card-container .cover-info {
    min-height: 0px !important;
  }
  .blog-page .blog-card .tag-card p,
  .blog-info-page .blog-card .tag-card p {
    font-size: 14px;
    line-height: 17.18px;
  }
  .blog-page .blog-card .image-card,
  .blog-info-page .blog-card .image-card {
    height: 150px;
  }
  .blog-page .regular-card-container .image-card,
  .blog-page .regular-card-container .image-card .ant-image,
  .blog-info-page .regular-card-container .image-card,
  .blog-info-page .regular-card-container .image-card .ant-image {
    max-width: 150px;
    height: 100px;
    margin: 0;
  }
  .blog-page .blog-card .cover-title,
  .blog-info-page .blog-card .cover-title {
    font-size: 24px;
    margin: 0px;
  }
  .blog-page .regular-card-container .cover-title,
  .blog-info-page .regular-card-container .cover-title {
    font-size: 20px;
    -webkit-line-clamp: 3;
  }
  .blog-page .blog-card .cover-info .time,
  .blog-info-page .blog-card .cover-info .time {
    order: 1;
    text-align: start;
  }
  .blog-page .blog-card .cover-info .autor,
  .blog-info-page .blog-card .cover-info .autor {
    order: 3;
    text-align: end;
  }
  .blog-page .blog-card .cover-info .date,
  .blog-info-page .blog-card .cover-info .date {
    order: 2;
    text-align: center;
  }
  .blog-page .regular-card-container .cover-info .autor,
  .blog-info-page .regular-card-container .cover-info .autor {
    display: none;
  }
  .blog-page .regular-card-container .cover-info .time,
  .blog-info-page .regular-card-container .cover-info .time {
    width: calc(50% - 5px) !important;
  }
  .blog-page .regular-card-container .cover-info .date,
  .blog-info-page .regular-card-container .cover-info .date {
    text-align: end;
    width: calc(50% - 5px) !important;
  }
  .blog-page .regular-card-container .cover-desc,
  .blog-info-page .regular-card-container .cover-desc {
    display: none !important;
  }
  .blog-page .blog-card .link-container,
  .blog-info-page .blog-card .link-container {
    display: none;
  }
}

@media only screen and (max-width: 430px) {
  .blog-page .blog-card .cover-info .time,
  .blog-info-page .blog-card .cover-info .time {
    max-width: 80px;
  }
  .blog-page .blog-card .cover-info .autor,
  .blog-info-page .blog-card .cover-info .autor {
    max-width: 80px;
  }
  .blog-page .blog-card .cover-info .date,
  .blog-info-page .blog-card .cover-info .date {
    max-width: 80px;
  }
  .blog-page .regular-card-container .cover-info .time,
  .blog-info-page .regular-card-container .cover-info .time {
    max-width: 60px;
  }
  .blog-page .regular-card-container .cover-info .autor,
  .blog-info-page .regular-card-container .cover-info .autor {
    max-width: 10px;
  }
  .blog-page .regular-card-container .cover-info .date,
  .blog-info-page .regular-card-container .cover-info .date {
    max-width: 80px;
  }
}

@media only screen and (max-width: 300px) {
  .blog-page .blog-card .cover-info .time,
  .blog-info-page .blog-card .cover-info .time {
    /* width: 15px !important; */
  }
  .blog-page .blog-card .cover-info .autor,
  .blog-info-page .blog-card .cover-info .autor {
    /* width: 15px !important; */
  }
  .blog-page .blog-card .cover-info .date,
  .blog-info-page .blog-card .cover-info .date {
    /* width: 15px !important; */
  }
  .blog-page .regular-card-container .cover-info .time,
  .blog-info-page .regular-card-container .cover-info .time {
    max-width: 30px !important;
  }
  .blog-page .regular-card-container .cover-info .autor,
  .blog-info-page .regular-card-container .cover-info .autor {
    max-width: 30px !important;
  }
  .blog-page .regular-card-container .cover-info .date,
  .blog-info-page .regular-card-container .cover-info .date {
    max-width: 30px !important;
  }
}
/* END SHARED STYLES */
