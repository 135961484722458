.blog-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  width: 100%;
  min-height: 80px;

  gap: 30px;
}
.blog-footer .sub-footer {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.blog-footer .sub-footer .pinned-extra {
  font-size: 14px;
  line-height: normal;
  font-weight: 600;
  letter-spacing: 0em;
  text-align: left;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  margin-bottom: 10px;
}
.blog-footer .blog-labels {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;

  gap: 10px;
}
.blog-footer .blog-labels .label {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  border-radius: 1px;
  padding: 0px 10px;
  background-color: rgba(217, 217, 217, 1);
  color: #444;
  font-size: 12px;
  line-height: 15px;
  font-weight: 600;
  letter-spacing: 0em;
  text-align: center;
  margin: 0px;
  transform: all ease 1s;
}
.blog-footer .blog-labels .label:hover,
.blog-footer .blog-labels .label:focus,
.blog-footer .blog-labels .label:active {
  color: #444 !important;
  box-shadow: 0.5px 0.5px 1px 0px rgba(0,0,0,0.5);
-webkit-box-shadow: 0.5px 0.5px 1px 0px rgba(0,0,0,0.5);
-moz-box-shadow: 0.5px 0.5px 1px 0px rgba(0,0,0,0.5);
}
.blog-footer .blog-share-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 5px;
  width: 200px;
}
.blog-footer .blog-share-buttons .ant-btn {
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  padding: 8px !important;
  border: 1px solid #ccc !important;
}
.blog-footer .blog-share-buttons .ant-btn span,
.blog-footer .blog-share-buttons .ant-btn svg {
  color: #444444;
}
.blog-footer .blog-share-buttons .ant-btn svg {
  color: #444444;
  /* color: #444444;
  width: 18px;
  height: 18px; */
}

@media only screen and (max-width: 762px) {
  .blog-footer {
    flex-direction: column;

    gap: 20px;
  }
}
