.page-container {
}
.blog-page .content-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 98%;
  max-width: 960px;
  margin: 30px auto;
}
.blog-page .content-container .main-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 690px;
  min-height: 100vh;
  gap: 30px 30px;
  padding-right: 30px;
}
.blog-page .content-container .main-content .spin-load {
  width: 100%;
  margin-bottom: -28.67px;
}
.blog-page .content-container .main-content .card-grid {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: space-between;
}
.blog-page .content-container .main-content .no-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: flex-start !important;
  width: 100%;
}
.blog-page .content-container .main-content .no-content p {
  font-size: 22px;
  font-family: "EMprint-Semibold";
  text-align: center;
  color: #444444;

  margin: 0px;
}
.blog-page .content-container .main-content .pagination-options {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-self: flex-end;
  width: 100%;
  /* gap: 10px; */
}
.blog-page .content-container .main-content .pagination-options .ant-pagination,
.blog-page
  .content-container
  .main-content
  .pagination-options
  .ant-pagination
  > li {
  height: 40px;
}
.blog-page
  .content-container
  .main-content
  .pagination-options
  .ant-pagination-item-link {
  height: 40px;
  width: 40px;
  border-radius: 4px;
  border: 1px solid #ccc;
  background: #fff;
  font-size: 14px;
  color: #ccc;
}
.blog-page
  .content-container
  .main-content
  .pagination-options
  .ant-pagination-disabled
  .ant-pagination-item-link {
  background-color: #aaa;
  color: #fff;
}
.blog-page
  .content-container
  .main-content
  .pagination-options
  .ant-pagination-disabled
  .ant-btn {
  background-color: #aaa;
}
.blog-page
  .content-container
  .main-content
  .pagination-options
  .ant-pagination-disabled
  .ant-btn
  span {
  color: #fff;
}
.blog-page
  .content-container
  .main-content
  .pagination-options
  .ant-pagination-item {
  border: 0;
  line-height: 38px;
  font-weight: 600;
}
.blog-page
  .content-container
  .main-content
  .pagination-options
  .ant-pagination-item
  a {
  height: 40px;
  width: 40px;
  border: 1px solid #ccc;
  color: #444;
  border-radius: 4px;
  font-weight: 600;
  font-family: var(--font-family);
}
.blog-page
  .content-container
  .main-content
  .pagination-options
  .ant-pagination-item-active
  a {
  color: var(--primary-color);
  border-color: var(--primary-color);
}
.blog-page .content-container .main-content .pagination-options .ant-btn {
  border-radius: 4px;
  border: 1px solid #ccc;
  background: #fff;
  height: 40px;
  width: 40px;
}
.blog-page
  .content-container
  .main-content
  .pagination-options
  .ant-btn.prev
  .anticon {
  transform: rotate(90deg);
}
.blog-page
  .content-container
  .main-content
  .pagination-options
  .ant-btn.next
  .anticon {
  transform: rotate(-90deg);
}
.blog-page .content-container .main-content .pagination-options .ant-btn span {
  color: #cccccc;
}
.blog-page .content-container .pinned-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 270px !important;
  scrollbar-width: thin;
  scrollbar-color: #444444 rgba(178, 178, 178, 0.599);
  -ms-overflow-style: none;
  gap: 10px;
  border-left: 1px dashed #cccccc;
  padding-left: 30px;
}
.blog-page .content-container .pinned-content .blog-card {
  border-bottom: 1px dashed #cccccc;
  padding-bottom: 20px;
}
.blog-page .content-container .pinned-content .blog-card:last-of-type {
  border-bottom: 0;
}
.blog-page .content-container .pinned-content .pinned-scroll {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  gap: 20px;
}
.blog-page .content-container .pinned-content::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: rgba(178, 178, 178, 0.599);
}

/* To Chrome, Safari y Edge(Chromium) */
.blog-page .content-container .pinned-content::-webkit-scrollbar {
  height: 10px;
  border-radius: 10px;
  background-color: rgba(178, 178, 178, 0.599);
}

.blog-page .content-container .pinned-content::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #444444;
}
.blog-page .content-container .pinned-content .pinned-extra {
  font-size: 20px;
  line-height: normal;
  font-weight: 600;
  letter-spacing: 0em;
  text-align: left;
  color: #444;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  margin: 20px 0 10px;
}

/* .blog-page .content-container .page-divider {
  display: flex;
  margin: 0px;

  height: 100% !important;

  border: 0.5px dashed rgba(204, 204, 204, 1) !important;
  border-spacing: 5px !important;
} */

@media (max-width: 1330px) {
  .blog-page .content-container {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;
    width: 95%;
  }
  .blog-page .content-container .main-content {
    padding: 0;
    width: 100%;
    max-width: 660px;
  }
  .blog-page .content-container .pinned-content {
    padding: 0;
    border-left: 0;
    gap: 0;
    width: 100% !important;
    max-width: 660px;
  }
  .blog-page .content-container .pinned-content .blog-card {
    border: 0;
  }
  .blog-page .content-container .pinned-content .pinned-scroll {
    flex-direction: row;
    overflow-x: scroll !important;
    width: 100% !important;
    max-width: 660px;
  }
  .blog-page
    .content-container
    .main-content
    .pagination-options
    .ant-pagination-item {
    display: none;
  }
  .blog-page
    .content-container
    .main-content
    .pagination-options
    .ant-pagination-options {
    display: none;
  }
}
@media only screen and (max-width: 762px) {
  .blog-page .header-container .flag {
    width: 235.5px;
    height: 71px;

    padding-left: 21px;
  }
  .blog-page .header-container .flag h1 {
    font-size: 24px;
    line-height: 29.45px;

    max-height: 60px !important;
  }
}

@media only screen and (max-width: 381px) {
  /* .blog-page .content-container {
    width: 80% !important;
  } */
}
