.blog-page .header-container,
.blog-info-page .header-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  /* height: 300px; */
  width: 100%;
}
.blog-page .header-container img,
.blog-info-page .header-container img {
  width: 100%;
  height: auto;
}
.blog-page .header-container .flag,
.blog-info-page .header-container .flag {
  z-index: 2;
  display: flex;
  align-items: center;

  position: absolute;

  width: 673px;
  height: 141px;

  padding-left: 139px;

  clip-path: polygon(0 0, 100% 0%, 85% 100%, 0% 100%);
  background-color: var(--secondary-dark-color);
}
.blog-page .header-container .flag h1,
.blog-info-page .header-container .flag h1 {
  font-size: 60px;
  line-height: 74px;
  font-weight: 600;
  letter-spacing: 0em;
  text-align: left;
  color: white;
  margin: 0px;
}
.blog-page .header-container .flag h1 span,
.blog-info-page .header-container .flag h1 span {
  display: block;
  margin: 0px;
}

@media only screen and (max-width: 762px) {

}
