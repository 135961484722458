div.workshop-type-cards::-webkit-scrollbar,
.workshop-services-container::-webkit-scrollbar {
  width: 0.15em;
  height: 0.5em;
  -ms-overflow-style: none;
  /* IE 11 */
  scrollbar-width: none;
  /* Firefox 64 */
}

div.workshop-type-cards::-webkit-scrollbar-track,
.workshop-services-container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px rgba(255, 255, 255, 0.288);
  background: #fff;
  border-radius: 10px;
}

div.workshop-type-cards::-webkit-scrollbar-thumb,
.workshop-services-container::-webkit-scrollbar-thumb {
  background: rgba(209, 211, 212, 0.267);
}

/* Handle on hover */

div.workshop-type-cards::-webkit-scrollbar-thumb:hover,
.workshop-services-container::-webkit-scrollbar-thumb:hover {
  background: #8080809a;
}

.end-col-container {
  margin: 2.5%;
}

.workshop-type-card {
  height: 170px;
  width: calc(25% - 25px);
  background-color: #f1f5ff;
  margin: 0.5%;
  display: inline-block;
  position: relative;
  border-radius: 10px;
  transition: 0.4s;
  cursor: pointer;
}

.workshop-type-card:hover {
  background-color: rgba(189, 215, 255, 0.5);
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16),
    0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
}

.workshop-type-card:active {
  background: rgba(189, 215, 255, 0.9);
  box-shadow: none;
  transition: 0.8s;
}

.workshop-type-card .workshop-type-card-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.workshop-card {
  width: 409px;
  background-color: white;
  margin: 0.5%;
  display: inline-block;
  position: relative;
  border-radius: 3%;
  transition: 0.4s;
  box-shadow: 0 0.25px 0.5px -2px rgba(0, 0, 0, 0.16),
    0 0.75px 1.5px 0 rgba(0, 0, 0, 0.12), 0 1.25px 3px 1px rgba(0, 0, 0, 0.09);
}

.workshop-card:hover {
  background-color: transparent;
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16),
    0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
}

.workshop-img-container {
  width: 409px;
  height: 230px;
}

.workshop-img-container img {
  width: 100%;
  /* height: -webkit-fill-available; */
  height: 171px;
  border-radius: 10px 10px 0px 0px;
}

.workshop-info div {
  display: inline-block;
}

.workshop-status .ant-badge-status-dot {
  width: 8px;
  height: 8px;
}

.end-footer {
  /* height: 20%; */
}

.end-button {
  width: "337px";
}

.end-header {
  height: 100%;
  width: 75%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.end-header div {
  display: inline-block;
}

.end-header-inputs .inputs-container {
  border: 0.5px solid var(--gray-light-color);
  border-radius: 15%;
  width: calc(90%-15px);
  height: 90%;
}

.filter.ant-popover-placement-bottom {
  max-width: 20% !important;
  position: fixed !important;
}

.filter
  > .ant-popover-content
  > .ant-popover-inner
  > .ant-popover-inner-content {
  padding: 6px !important;
}

.filter.ant-select > .ant-select-selector > .ant-select-selection-placeholder {
  /* position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  font-size: 18px; */
}

.filter
  .ant-select-selector
  .ant-select-single.ant-select-show-arrow
  .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  font-size: 18px;
  margin-top: 4px;
}

.filter-no-animation {
  /* animation-name: backgroundFiltersColorPalette;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear; */
  height: auto;
  padding: 30px 18px;
  border-radius: 0 0 10px 10px;
  /* background: #2f66f2; */
}

.filter-no-animation-dark {
  /* animation-name: backgroundFiltersColorPalette;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear; */
  height: auto;
  padding: 30px 18px;
  border-radius: 0 0 10px 10px;
  /* background: #012379e0; */
}

.ant-popover {
  border-radius: 15px;
}

.filter-input div.ant-popover-content div.ant-popover-inner {
  height: 475px;
  overflow: auto;
}

.responsive-header {
  box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.25),
    0 16px 12px -12px rgba(0, 0, 0, 0.151);
}

.workshop-city {
  text-align: end;
}

/* CSS STYLES FOR THE AUTO COMPLETE MAP */

.pac-container {
  background-color: #fff;
  z-index: 1110;
  font-size: 13px;
  overflow: hidden;
  width: 100%;
}

.pac-icon {
  width: 15px;
  height: 20px;
  margin-right: 7px;
  margin-top: 6px;
  display: inline-block;
  vertical-align: top;
  background-image: url(https://maps.gstatic.com/mapfiles/api-3/images/autocomplete-icons.png);
  background-size: 34px;
}

.pac-icon-marker {
  background-position: -1px -161px;
}

.pac-item {
  cursor: pointer;
  padding: 0 4px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 30px;
  vertical-align: middle;
  text-align: left;
  color: #454545;
}

.pac-item.custom {
  background-color: #fff9c4;
}

.pac-item:hover {
  background-color: #f1f5ff;
  border-radius: 15px;
  border: none;
}

.pac-item-error,
.pac-item-error:hover {
  color: #aaa;
  padding: 0 5px;
  cursor: default;
  background-color: #fff;
}

.emergency-tag .ant-tag-close-icon {
  font-size: 14px !important;
  margin-left: 10px !important;
}

.wd-container {
  padding: 2%;
  margin: 0 !important;
}

.ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
}

.rating-collapse .ant-collapse-header {
  padding: 0 !important;
  padding-right: 10px !important;
}

.rating-collapse
  div.ant-collapse-header
  span.anticon.anticon-right.ant-collapse-arrow {
  position: absolute !important;
  top: 50% !important;
  transform: translate(100%, -25%) !important;
}

.rate-details {
  stroke: black;
}

.zindex-message {
  z-index: 3000;
}

.ant-spin-blur::after {
  opacity: 1 !important;
}

.spin-exxon {
  /* background-color: white; */
  height: 100%;
}

.btn-on-map {
  cursor: pointer;
  margin: 15px;
  text-align: center;
  position: absolute;
  top: 9px !important;
}

.input-navbar input.ant-input {
  font-size: 18px;
}

.filter div.ant-select-selector span.ant-select-selection-item {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 3px;
  font-size: 18px;
}

.btn-icon span.anticon svg {
  transform: translate(0, 15%);
}

.bubble {
  width: 24px !important;
  min-width: 24px !important;
  height: 24px !important;
  background: var(--carker-blue);
  border-radius: 50px;
  color: white;
  font-size: 16px;
  text-align: center;
}

.bubble-text {
  scroll-padding-inline: 10px;
  font-size: 17px;
  font-family: Emprint-regular;
  margin: 0 0.8rem;
}

.bubble-head {
  display: flex;
  align-items: center;
}

.card-address {
  font-size: 16px;
}

.whitecard-border {
  border: solid;
  border-radius: 100%;
  border-color: #ffffff;
}

.whitecard-text {
  font-family: "Emprint-regular";
  font-weight: 300;
  color: #ffffff;
}

.whitecard-name {
  font-weight: normal;
  font-style: italic;
  text-align: right;
}

.footer {
  width: 100%;
  height: 308 px;
  background-color: #f5f5f5;
}

.footer-text {
  color: #808080;
  font-size: 16px;
}
.footer-text:hover {
  color: #808080 !important;
}

.second-footer {
  height: 219px;
}

.li-margin {
  margin-bottom: 0.8rem;
}

#avatar article {
  font-family: "emprint-semibold";
}

.slant {
  margin: 0 40px;
  padding: 5px 5px;
  background: #5e62ff;
  transform: skew(-10deg, 0deg);
}

.img-slant {
  transform: skew(10deg, 0deg);
}

@media only screen and (max-width: 1200px) {
  .two-columns {
    columns: 1;
    -webkit-columns: 1 !important;
    -moz-columns: 1;
    padding: 0;
    text-align: center !important;
  }
  .second-footer {
    padding: 15px 0 0 0 !important;
    height: auto !important;
  }
  .footer {
    padding: 30px 24px 5px !important;
    text-align: -webkit-center;
    height: auto !important;
  }
  .mobile-center {
    float: none !important;
  }
}

@media only screen and (max-width: 1024px) {
  .workshop-city {
    text-align: start;
  }
  .end-logo-container img {
    width: 60%;
  }
  .responsive-header {
    padding: 0 3%;
  }
  .end-col-container {
    margin: 0;
  }
  .workshop-type-card {
    margin: 0.75%;
    width: 190px;
    height: 115px;
  }
  .workshop-card {
    margin: 1%;
    /* width: calc(70% - 25px); */
  }
  div.workshop-type-cards::-webkit-scrollbar,
  .workshop-services-container::-webkit-scrollbar,
  .mobile::-webkit-scrollbar {
    display: none;
    scrollbar-width: none;
  }
  .workshop-img-container {
    width: 100%;
    /* height: 100%; */
    height: 171px;
  }
}

@media only screen and (max-width: 992px) {
  .wd-container {
    padding: 0;
    margin: 0 !important;
  }
  .title-light.responsive {
    font-size: 26px !important;
    margin-top: 27px !important;
  }
}

@media only screen and (max-width: 768px) {
  .btn-endconsumer-container.responsive {
    bottom: 1% !important;
  }
  .btn-endconsumer-container.responsive div button {
    height: 60px !important;
  }
  /* .workshop-type-card {
    margin: 0.75%;
    width: calc(75% - 25px);
  } */
  .workshop-card {
    margin: 1%;
    width: calc(60% - 25px);
  }
  .workshop-img-container {
    height: 215px;
  }
}

@media only screen and (max-width: 624px) {
  /* .workshop-type-card {
    margin: 0.75%;
    width: calc(75% - 25px);
  } */
  .workshop-card {
    margin: 1%;
    width: calc(75% - 25px);
  }
  .workshop-img-container {
    height: 190px;
  }
}

@media only screen and (max-width: 411px) {
  .btn-endconsumer-container.responsive {
    bottom: 1% !important;
  }
  .btn-endconsumer-container.responsive div button {
    height: 60px !important;
  }
  .workshop-card {
    margin: 2%;
    width: calc(100% - 25px);
  }
  .workshop-img-container {
    height: 170px;
  }
}

@media only screen and (max-width: 360px) {
  .btn-endconsumer-container.responsive {
    bottom: 1% !important;
  }
  .btn-endconsumer-container.responsive div button {
    height: 60px !important;
  }
  /* .workshop-type-card {
    margin: 0.75%;
    width: calc(75% - 25px);
  } */
  .workshop-card {
    margin: 1%;
    width: calc(95% - 25px);
  }
  .end-button {
    width: 100%;
  }
}

@media only screen and (max-height: 760px) {
  /* .info-div {
    height: 100vh !important;
  } */
}

@media only screen and (max-height: 667px) {
  .side-sub-title .end-button {
    margin: 0 !important;
  }
}

@media only screen and (max-width: 449px) {
  .div-header-aboutus {
    /*width: 100%;*/
    padding-top: 0px;
    background-color: var(--primary-color);
    /*clip-path: polygon(0 0, 250px 0px, 75px 250px, 0px 100px);*/
    clip-path: polygon(0 0, 100% 0, 85% 100%, 0 100%);
    padding-left: 5%;
    padding-right: 8%;
    height: 50px;
    display: flex;
    align-items: center;
  }
  .div-side-aboutus {
    width: 100%;
    background-color: var(--primary-color);
    clip-path: polygon(30px 0px, 100% 0px, 0px 950px, 0px 105px);
    padding: 15px 0;
  }
  .div-secondary-aboutus {
    clip-path: polygon(30px 0px, 190px 0px, 100px 160px, 0px 40px);
    background: orange;
    padding: 0 30px;
  }
  .div-header-contact {
    /* width: 647px; */
    padding-top: 20px;
    height: 50px;
    width: 250px;
    background-color: var(--primary-color);
    clip-path: polygon(0 0, 250px 0px, 75px 250px, 0px 100px);
    display: flex;
    align-items: center;
    padding-left: 5%;
  }
  .div-header-contactus {
    /* width: 647px; */
    padding-top: 20px;
    height: 50px;
    width: 100%;
    background-color: var(--primary-color);
    clip-path: polygon(75px 0, 100% 0px, 100% 100%, 0px 100px);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    /* align-self: flex-end; */
    /* justify-self: flex-end; */
    padding-right: 5%;
  }
  .div-header-rewards {
    /* width: 647px; */
    padding-top: 20px;
    height: 50px;
    width: 100%;
    background-color: var(--primary-color);
    clip-path: polygon(75px 0, 100% 0px, 100% 100%, 0px 100px);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    /* align-self: flex-end; */
    /* justify-self: flex-end; */
    padding-right: 5%;
  }

  .div-header-left {
    height: 50px;
    width: 100%;
    background-color: var(--primary-color);
    clip-path: polygon(0 0, 250px 0px, 0px 250px, 0px 0px);
    display: flex;
    align-items: center;
    padding-left: 5%;
  }
  .left-header-container {
    height: 70px;
    width: 250px;
  }

  .rewards-header-container {
    height: 70px;
    width: 250px;
  }
}

@media only screen and (max-width: 769px) and (min-width: 450px) {
  .div-header-aboutus {
    /*width: 100%;*/
    padding-top: 1px;
    background-color: var(--primary-color);
    /*clip-path: polygon(0 0, 300px 0px, 160px 300px, 0px 100px);*/
    clip-path: polygon(0 0, 100% 0, 84% 100%, 0 100%);
    padding-left: 3%;
    padding-right: 5%;
    height: 74px;
    display: flex;
    align-items: center;
  }
  .div-side-aboutus {
    width: 100%;
    background-color: var(--primary-color);
    clip-path: polygon(30px 0px, 100% 0px, 400px 950px, 0px 105px);
    padding: 15px 0;
  }
  .div-secondary-aboutus {
    clip-path: polygon(20px 0px, 180px 0px, 0px 420px, 0px 50px);
    background: orange;
    padding: 0 30px;
  }
  .div-header-contact {
    padding-top: 20px;
    height: 74px;
    width: 100%;
    background-color: var(--primary-color);
    clip-path: polygon(0 0, 300px 0px, 75px 300px, 0px 100px);
    display: flex;
    align-items: center;
    padding-left: 3%;
  }
  .div-header-contactus {
    /* width: 647px; */
    padding-top: 20px;
    height: 74px;
    width: 100%;
    background-color: var(--primary-color);
    clip-path: polygon(75px 0, 100% 0px, 100% 100%, 0px 100px);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 3%;
  }

  .div-header-left {
    height: 74px;
    width: 100%;
    background-color: var(--primary-color);
    clip-path: polygon(0 0, 300px 0px, 0px 300px, 0px 0px);
    display: flex;
    align-items: center;
    padding-left: 3%;
  }

  .left-header-container {
    height: 100%;
    width: 300px;
  }

  .rewards-header-container {
    height: 100%;
    width: 300px;
  }
}

@media only screen and (max-width: 1280px) and (min-width: 769px) {
  .div-header-aboutus {
    /*width: 100%;*/
    padding-top: 5px;
    background-color: var(--primary-color);
    /*clip-path: polygon(0 0, 400px 0px, 75px 400px, 0px 100px);*/
    clip-path: polygon(0 0, 100% 0, 83% 100%, 0 100%);
    align-items: center;
    padding-left: 3%;
    padding-right: 5%;
    height: 84px;
    display: flex;
  }
  .div-side-aboutus {
    width: 100%;
    background-color: var(--primary-color);
    clip-path: polygon(75px 0px, 100% 0px, 0px 950px, 0px 150px);
    padding: 15px 0;
  }
  .div-secondary-aboutus {
    clip-path: polygon(30px 0px, 200px 0px, 30px 440px, 0px 60px);
    background: orange;
    padding: 0 30px;
  }
  .div-header-contact {
    padding-top: 25px;
    height: 84px;
    width: 100%;
    background-color: var(--primary-color);
    clip-path: polygon(0 0, 400px 0px, 75px 400px, 0px 100px);
    display: flex;
    align-items: center;
    padding-left: 3%;
  }
  .div-header-contactus {
    padding-top: 25px;
    height: 84px;
    width: 100%;
    background-color: var(--primary-color);
    clip-path: polygon(75px 0, 100% 0px, 100% 100%, 0px 100px);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 3%;
  }

  .div-header-left {
    height: 84px;
    width: 100%;
    background-color: var(--primary-color);
    clip-path: polygon(0 0, 400px 0px, 0px 400px, 0px 0px);
    display: flex;
    align-items: center;
    padding-left: 3%;
  }

  .left-header-container {
    height: 100%;
    width: 400px;
  }

  .rewards-header-container {
    height: 100%;
    width: 400px;
  }
}

@media only screen and (min-width: 1281px) and (max-width: 2700px) {
  .div-header-aboutus {
    /*width: 100%;*/
    background-color: var(--primary-color);
    /*clip-path: polygon(0 0, 650px 0px, 0px 900px, 0px 0px);*/
    clip-path: polygon(0 0, 100% 0, 85% 100%, 0 100%);
    align-items: center;
    padding-left: 5%;
    padding-right: 5%;
    height: 94px;
    display: flex;
  }

  .div-side-aboutus {
    width: 100%;
    background-color: var(--primary-color);
    clip-path: polygon(30px 0px, 100% 0px, 400px 950px, 0px 105px);
    padding: 15px 0;
  }
  .div-secondary-aboutus {
    clip-path: polygon(30px 0px, 360px 0px, 130px 440px, 0px 60px);
    background: orange;
    padding: 0 30px 0 21px;
  }
  .div-header-contact {
    padding-top: 50px;
    height: 94px;
    width: 100%;
    background-color: var(--primary-color);
    clip-path: polygon(0 0, 700px 0px, 75px 700px, 0px 100px);
    display: flex;
    align-items: center;
    padding-left: 5%;
  }
  .div-header-contactus {
    padding-top: 50px;
    height: 94px;
    width: 100%;
    background-color: var(--primary-color);
    clip-path: polygon(75px 0, 100% 0px, 100% 100%, 0px 100px);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 5%;
  }

  .div-header-left {
    height: 94px;
    width: 100%;
    background-color: var(--primary-color);
    clip-path: polygon(0 0, 700px 0px, 0px 700px, 0px 0px);
    display: flex;
    align-items: center;
    padding-left: 5%;
  }

  .left-header-container {
    height: 100%;
    width: 550px;
  }

  .rewards-header-container {
    height: 100%;
    width: 550px;
  }
}

@media only screen and (min-width: 2701px) {
  .div-header-aboutus {
    padding-top: 50px;
    height: 94px;
    /*width: 100%;*/
    background-color: var(--primary-color);
    /*clip-path: polygon(0 0, 700px 0px, 75px 700px, 0px 100px);*/
    clip-path: polygon(0 0, 100% 0, 85% 100%, 0 100%);
    display: flex;
    align-items: center;
    padding-left: 5%;
    padding-right: 5%;
  }
  .div-header-contact {
    padding-top: 50px;
    height: 94px;
    width: 100%;
    background-color: var(--primary-color);
    clip-path: polygon(0 0, 750px 0px, 75px 750px, 0px 100px);
    display: flex;
    align-items: center;
    padding-left: 5%;
  }
  .div-header-contactus {
    padding-top: 50px;
    height: 94px;
    width: 100%;
    background-color: var(--primary-color);
    clip-path: polygon(0 0, 500px 0px, 75px 500px, 0px 100px);
    display: flex;
    align-items: center;
    padding-left: 5%;
  }
  .div-header-contactus {
    padding-top: 50px;
    height: 94px;
    width: 100%;
    background-color: var(--primary-color);
    clip-path: polygon(75px 0, 100% 0px, 100% 100%, 0px 100px);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 5%;
  }

  .div-header-left {
    height: 94px;
    /*width: 100%;*/
    background-color: var(--primary-color);
    clip-path: polygon(0 0, 700px 0px, 0px 700px, 0px 0px);
    display: flex;
    align-items: center;
    padding-left: 5%;
  }
  .left-header-container {
    height: 100%;
    width: 550px;
  }

  .div-header-rewards {
    /* width: 647px; */
    padding-top: 50px;
    height: 94px;
    width: 100%;
    background-color: var(--primary-color);
    clip-path: polygon(75px 0, 100% 0px, 100% 100%, 0px 100px);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    /* align-self: flex-end; */
    /* justify-self: flex-end; */
    padding-right: 5%;
  }
  .rewards-header-container {
    height: 100%;
    width: 550px;
  }
}

.amazon-cards {
  border-width: 1px;
  border-style: solid;
  border-color: #a8a8a8;
}

@media only screen and (max-width: 450px) {
  .div-header-right {
    /* width: 647px; */
    padding-top: 20px;
    height: 50px;
    width: 100%;
    background-color: #2f66f2;
    clip-path: polygon(75px 0, 100% 0px, 100% 100%, 0px 100px);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    /* align-self: flex-end; */
    /* justify-self: flex-end; */
    padding-right: 5%;
  }
  .right-header-container {
    height: 70px;
    width: 280px;
  }
}

@media only screen and (max-width: 769px) and (min-width: 450px) {
  .div-header-right {
    /* width: 647px; */
    padding-top: 20px;
    height: 74px;
    width: 100%;
    background-color: #2f66f2;
    clip-path: polygon(75px 0, 100% 0px, 100% 100%, 0px 100px);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 5%;
  }
  .right-header-container {
    height: 100%;
    width: 300px;
  }
}

@media only screen and (max-width: 1280px) and (min-width: 769px) {
  .div-header-right {
    /* width: 647px; */
    padding-top: 25px;
    height: 84px;
    width: 100%;
    background-color: #2f66f2;
    clip-path: polygon(75px 0, 100% 0px, 100% 100%, 0px 100px);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    /* align-self: flex-end; */
    /* justify-self: flex-end; */
    padding-right: 5%;
  }
  .right-header-container {
    height: 100%;
    width: 400px;
  }
}

@media only screen and (min-width: 1281px) and (max-width: 2700px) {
  .div-header-right {
    /* width: 647px; */
    padding-top: 50px;
    height: 94px;
    width: 100%;
    background-color: #2f66f2;
    clip-path: polygon(75px 0, 100% 0px, 100% 100%, 0px 100px);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    /* align-self: flex-end; */
    /* justify-self: flex-end; */
    padding-right: 5%;
  }
  .right-header-container {
    height: 100%;
    width: 570px;
  }
}

@media only screen and (min-width: 2701px) {
  .div-header-right {
    /* width: 647px; */
    padding-top: 50px;
    height: 94px;
    width: 100%;
    background-color: #2f66f2;
    clip-path: polygon(75px 0, 100% 0px, 100% 100%, 0px 100px);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    /* align-self: flex-end; */
    /* justify-self: flex-end; */
    padding-right: 5%;
  }
  .right-header-container {
    height: 100%;
    width: 570px;
  }
}

.carousel-packs {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #5e62ff;
  /* flex-wrap: wrap; */
}

/* .carousel-packs div {
  background-color: #fff9c4;
} */

/* .carousel-packs div:hover {
  border: 1px solid #454545;
} */

.card-on-hover {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  padding: 5% 5% 0.5% 5%;
}

.card-on-hover:hover {
  align-items: center;
  justify-content: flex-start;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 5% 5% 5% 5%;
}

.card-on-hover .off-hover-title {
  font-size: 22px;
  line-height: 22px;
  color: white;
  text-shadow: 1px 2px 5px black;
}

.card-on-hover:hover .off-hover-title {
  text-shadow: none;
  text-align: center;
}

.card-on-hover .on-hover-desc {
  display: none;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  color: white;
}

.card-on-hover:hover .on-hover-desc {
  display: block;
}

.card-on-hover .on-hover-btn {
  display: none;
  font-size: 18px;
  line-height: 18px;
  color: white;
  background-color: rgb(240, 88, 34);
  border: none;
}

.card-on-hover:hover .on-hover-btn {
  display: block;
}

.card-on-hover .on-hover-space {
  display: none;
  flex: 1;
}

.card-on-hover:hover .on-hover-space {
  display: block;
}

.arrow-btn {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.pack-info {
  display: flex;
  flex-direction: column;
}

#landing_page-videos-container {
}
#landing_page-videos-container > div {
  width: 358px;
  height: 201px;
  margin: 8px;
}

@media (max-width: 576px) {
  #landing_page-videos-container > div {
    width: 280px;
    height: 157px;
  }
}

@media only screen and (max-width: 1200px) {
  .is-mobile {
    display: none;
  }
}
