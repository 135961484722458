.login-page-background {
  background-image: url(../../assets/Images/img-login-taller.png);
  background-color: inherit;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position: center;
  background-size: cover;
  height: 100%;
}
.content-row-login-page{
  min-height: 648px;
  height: 100%;
}
.main-container-login-page {
  display: flex;
  flex-direction: column;
  background: rgb(28,53,127);
  background: radial-gradient(circle, rgba(28,53,127,1) 0%, rgba(35,103,187,1) 53%, rgba(70,80,137,1) 100%);
}
@media (max-width: 514px) {
  .login-page-background {
    background-image: none;
  }
  .content-row-login-page{
    padding: 0;
  }
}
@media (max-width: 991px) {
  .main-container-login-page {
    height: 100%;
  }
}


@media (min-width: 992px) {
  .min-height-login {
    min-height: 623px;
    padding: 3%;
  }
}

