.blog-info-page .content-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 98%;
  max-width: 960px;
  margin: 30px auto;
}
.blog-info-page .content-container .main-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 690px;
  gap: 10px;
  padding-right: 30px;
}
.blog-info-page .content-container .pinned-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 270px !important;
  scrollbar-width: thin;
  scrollbar-color: #444444 rgba(178, 178, 178, 0.599);
  -ms-overflow-style: none;
  gap: 10px;
  border-left: 1px dashed #CCCCCC;
  padding-left: 30px;
}
.blog-info-page .content-container .pinned-content .blog-card {
  border-bottom: 1px dashed #CCCCCC;
  padding-bottom: 20px;
}
.blog-info-page .content-container .pinned-content .blog-card:last-of-type {
  border-bottom: 0;
}
.blog-info-page .content-container .pinned-content .pinned-scroll {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  gap: 20px;
}
.blog-info-page
  .content-container
  .pinned-content::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: rgba(178, 178, 178, 0.599);
}

/* To Chrome, Safari y Edge(Chromium) */
.blog-info-page .content-container .pinned-content::-webkit-scrollbar {
  height: 10px;
  border-radius: 10px;
  background-color: rgba(178, 178, 178, 0.599);
}

.blog-info-page
  .content-container
  .pinned-content::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #444444;
}
.blog-info-page .content-container .pinned-content .pinned-extra {
  font-size: 20px;
  line-height: normal;
  font-weight: 600 !important;
  letter-spacing: 0em;
  text-align: left;
  color: #444;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  margin: 20px 0 10px;
}

@media (max-width: 1330px) {
  .blog-info-page .content-container {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;
    width: 95%;
  }
  .blog-info-page .content-container .main-content {
    padding: 0;
    width: 100%;
    max-width: 660px;
  }
  .blog-info-page .content-container .pinned-content {
    padding: 0;
    border-left: 0;
    gap: 0;
    width: 100% !important;
    max-width: 660px;
  }
  .blog-info-page .content-container .pinned-content .blog-card {
    border: 0;
  }
  .blog-info-page .content-container .pinned-content .pinned-scroll {
    flex-direction: row;
    overflow-x: scroll !important;
    width: 100% !important;
    max-width: 660px;
  }
}
@media only screen and (max-width: 762px) {
  
  .blog-info-page .header-container .flag {
    width: 235.5px;
    height: 71px;

    padding-left: 21px;
  }
  .blog-info-page .header-container .flag h1 {
    font-size: 24px;
    line-height: 29.45px;

    max-height: 60px !important;
  }

}
