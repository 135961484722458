/* ==========================================================================
  Content
  ========================================================================== */
.blog-info-page .post-content {
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
  max-width: 100%;
  gap: 15px;
  flex-direction: column;
  width: 100%;
}
.blog-info-page .post-content p,
.blog-info-page .post-content p span,
.blog-info-page .post-content li,
.blog-info-page .post-content li span,
.blog-info-page .post-content blockquote,
.blog-info-page .post-content blockquote span {
  /* color: #000 !important; */
  color: #444 !important;
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 0;
}
.blog-info-page .post-content p,
.blog-info-page .post-content blockquote,
.blog-info-page .post-content ul,
.blog-info-page .post-content ol {
  margin-bottom: 15px;
}
.blog-info-page .post-content blockquote,
.blog-info-page .post-content blockquote span {
  font-size: 14px;
  line-height: 22px;
}
.blog-info-page .post-content blockquote {
  margin-left: 10px;
  padding: 2px 0 5px 10px;
  border-left: 3px solid var(--mariner-color);
}

.blog-info-page .post-content h2 {
  color: #000 !important;
  font-family: var(--font-family);
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
  margin-bottom: 0px;
  letter-spacing: 0;
}
.blog-info-page .post-content h3 {
  color: #000 !important;
  font-family: var(--font-family);
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 0px;
  letter-spacing: 0;
}
.blog-info-page .post-content strong,
.blog-info-page .post-content b {
  font-weight: 600 !important;
}
.blog-info-page .post-content ul,
.blog-info-page .post-content ol {
  padding-left: 20px;
}
.blog-info-page .post-content a {
  color: var(--mariner-color) !important;
  font-weight: 600;
}

.embed-responsive-wrapper {
  position: relative;
  top: 0;
  left: 0;
  margin: 0 auto 15px auto;
}
.embed-responsive-wrapper iframe[src*="anchor"] {
  width: 100%;
  height: auto;
}
.embed-responsive-wrapper twitter-widget,
.embed-responsive-wrapper iframe,
.embed-responsive-wrapper embed,
.embed-responsive-wrapper object,
.embed-responsive-wrapper video {
  width: auto !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.blog-info-page .post-content .embed-responsive {
  position: relative;
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
  margin: 0 auto 15px auto;
}
.blog-info-page .post-content .embed-responsive::before {
  display: block;
  content: "";
}
.blog-info-page .post-content .embed-responsive .embed-responsive-item,
.blog-info-page .post-content .embed-responsive .fb_iframe_widget,
.blog-info-page .post-content .embed-responsive iframe,
.blog-info-page .post-content .embed-responsive embed,
.blog-info-page .post-content .embed-responsive object,
.blog-info-page .post-content .embed-responsive video,
.blog-info-page .post-content .embed-responsive div[data-player] {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  border: 0 !important;
}
.blog-info-page .post-content .embed-responsive.embed-responsive-21by9::before {
  padding-top: 42.857143%;
}
.blog-info-page .post-content .embed-responsive.embed-responsive-16by9::before {
  padding-top: 56.25%;
}
.blog-info-page .post-content .embed-responsive.embed-responsive-4by3::before {
  padding-top: 75%;
}
.blog-info-page .post-content .embed-responsive.embed-responsive-1by1::before {
  padding-top: 100%;
}
.blog-info-page .post-content .video-content video {
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
}
.blog-info-page .post-content .image-content {
  border-radius: 5px;
  overflow: hidden;
  margin: 0 auto 15px auto;
}
/* ==========================================================================
  Others
  ========================================================================== */
.embed-responsive-wrapper {
  display: flex;
  align-items: center;
}
.embed-responsive-wrapper iframe {
  width: 100% !important;
}
.instagram-media {
  align-self: center !important;
  min-width: 100% !important;
}
